import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import SEO from '../../components/Seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO description="Using TypeScript with React Router." title="React Router" keywords={['react router', 'router', 'withRouter', 'url parameters', 'route parameters']} mdxType="SEO" />
    <h1 {...{
      "id": "react-router"
    }}>{`React Router`}</h1>
    <h2 {...{
      "id": "withrouter"
    }}>{`withRouter`}</h2>
    <p>{`To use a component injected with `}<inlineCode parentName="p">{`withRouter`}</inlineCode>{` props, import and use `}<inlineCode parentName="p">{`RouteComponentProps`}</inlineCode>{` (`}<a parentName="p" {...{
        "href": "https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/react-router/index.d.ts#L71"
      }}>{`declaration`}</a>{`) from `}<inlineCode parentName="p">{`react-router`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { RouteComponentProps, withRouter } from 'react-router';

type SomeComponentProps = RouteComponentProps;

const SomeComponent: React.FC<SomeComponentProps> = ({ history }) => {
  const goHome = () => history.push('/home');

  return <button onClick={goHome}>Go Home</button>;
};

export default withRouter(SomeComponent);
`}</code></pre>
    <h2 {...{
      "id": "url-parameters"
    }}>{`URL Parameters`}</h2>
    <p>{`To type URL parameters, import and use `}<inlineCode parentName="p">{`RouteComponentProps`}</inlineCode>{` (`}<a parentName="p" {...{
        "href": "https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/react-router/index.d.ts#L71"
      }}>{`declaration`}</a>{`) from `}<inlineCode parentName="p">{`react-router`}</inlineCode>{`. Pass your parameter definitions as a type variable to `}<inlineCode parentName="p">{`RouteComponentProps`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// Router.tsx
import { BrowserRouter, Route } from 'react-router-dom';

const Router = () => {
  return (
    <BrowserRouter>
      <Route exact path="/books/:id" component={Books} />
    </BrowserRouter>
  );
};

// BookDetail.tsx
import { RouteComponentProps, withRouter } from 'react-router';

type BookDetailParams = {
  id: string; // parameters will always be a string (even if they are numerical)
};

type BookDetailProps = RouteComponentProps<BookDetailParams>;

const BookDetail: React.FC<> = ({ match }) => {
  return <div>Book ID: {match.params.id}</div>;
};

export default withRouter(BookDetail);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      